const en = {
  more: `More like this`,
  noresults: `No results.`,
  hungarian: `Hungarian`,
  english: `English`,
  home: `Home`,
  search: `Search`,
  mytickets: `My tickets`,
  otherlang: `Magyar`,
  buyticket: `Get tickets`,
  information: `Information`,
  info_desc: `Find everything you need to know`,
  info_faq: `Frequently Asked Questions`,
  info_terms: `Terms and conditions`,
  info_privacy: `Privacy policy`,
  contact: `Contact`,
  fororgs: `For organizers`,
  org_desc: `Online tickets, pre-sale for clubs, festivals, event organizers and bands`,
  interested: `Tell me more`,
  providers: `Providers`,
  seatneed: `Tickets are sold by seating`,
  reserve: `Reserve`,
  buy: `Buy tickets`,
  reserveticket: `Reserve tickets`,
  coupon: `Coupon code`,
  havecoupon: `I have a Coupon code`,
  couponcode: `Coupon code`,
  code: `Code`,
  havecode: `Apply code`,
  seats: `Selected seats`,
  standings: `Standing tickets`,
  personalinfo: `Personal information`,
  queue: `Queue`,
  summarypay: `Order summary`,
  summarynopay: `Order summary`,
  name: `Name`,
  email: `Email address`,
  emailconfirm: `Confirm email address`,
  phone: `Phone number`,
  zip: `Postal code`,
  city: `City`,
  address: `Street address`,
  diffinvoce: `Different invoice details`,
  invname: `Name on invoice`,
  invvat: `VAT number`,
  dateentryformat: `mm/dd/yyyy`,
  ordercomment: `Comments about order`,
  password: `Password`,
  passagain: `Password again`,
  cancel: `Cancel`,
  next: `Next`,
  shipping: `Shipping`,
  receivemethod: `How would you like to get it?`,
  post: `Via post`,
  eticket: `E-ticket`,
  inlandonly: `Delivery is only available within Hungary`,
  eticketformats: `Downloadable as PDF, Apple Wallet and Google Wallet formats`,
  payment: `Payment`,
  paymentmethod: `How would you like to pay?`,
  cardterms: `Information`,
  terms: `Terms`,
  acceptTerms: `I accept the terms`,
  back: `Back`,
  alltogether: `Total:`,
  pay: `Pay`,
  momentplease: `Please be patient...`,
  highdemand: `This event is quite popular at the moment. As soon as we can fulfill your request we will forward you to the cart.`,
  afewminutes: `Depending on the load this can take a few.`,
  queuelocation: `Your place in the queue`,
  timeout: `Timeout`,
  timesup: `Due to inactivity, we had to release your cart to another customer.`,
  reload: `Reload`,
  close: `Close`,
  waitpay: `Waiting on payment clearance...`,
  plsnoreload: `Do not refresh the page.`,
  paysuccess: `Successful purchase!`,
  paysuccessdesc: `We sent your tickets and the invoice to your email address`,
  paysuccessthanks: `Thank you very much! Enjoy!`,
  date: `Date`,
  orderid: `Order Number`,
  pay_ref: `Payment ID`,
  pay_met: `Payment method`,
  totickets: `To My tickets`,
  payfailure: `Unsuccessful transaction.`,
  payfailuredesc: `Please make sure you enter valid data. If everything seems OK, please contact the card-issuer bank to see why this request was denied.`,
  payfailuredesc2: `If you think it's something on TIXA's side, let us know by contacting us.`,
  lostmypass: `I forgot my password`,
  confirm: `Confirm`,
  sgwrong: `Something is not right.`,
  idplink: `This is your first time using Facebook sign-in but you already have an account with us. Please enter your password in order to continue.`,
  restore: `Restore`,
  cantrestore: `Restore was unsuccessful.`,
  newpass: `New password`,
  newpass2: `New password again`,
  restorecode: `Restore code`,
  restoreinst: `To restore your account, please enter the code we sent you.`,
  uforgot: `Forgot your password?`,
  nofbsosad: `Facebook sign-in is not available at the moment.`,
  iforgot: `Forgot my password`,
  register: `Register`,
  registration: `Registration`,
  login: `Sign in`,
  sendcode: `Send code`,
  nosuchuser: `We couldn't find this user.`,
  codeinst: `No problem! If you know the email address you registered with, we can send you a restore code.`,
  weakpass: `This password is too weak.`,
  onecart: `Only one cart can be opened at a time.`,
  valuemissing: `Please fill out this field.`,
  typemismatch: `The entered value is not valid.`,
  passnomatch: `The two passwords don't match.`,
  emailnomatch: `The two email addresses don't match.`,
  passtooshort: `A good password is at least 8 characters long.`,
  userexists: `There is already an account with this email address.`,
  inactiveprice: `Inactive price`,
  soldout: `Sold out`,
  doortime: `Gate time`,
  getalltickets: `Get all tickets`,
  setsell: `Put up for sell`,
  geticket: `Get ticket`,
  olderorders: `Older purchases`,
  noticketsyet: `You don't have any tickets yet.`,
  order: `Order`,
  ordernumber: `Order Number`,
  card: `Credit or debit card`,
  otpszep: `OTP SZÉP Card`,
  otpszepszallas: `OTP SZÉP Card`,
  mkbszep: `MKB SZÉP Card`,
  mkbszepszallas: `MKB SZÉP Card`,
  knhszep: `K&H SZÉP Card`,
  accountcredit: `TIXA account credits`,
  availablebalance: `Available balance`,
  insufficientbalance: `Insufficient funds available on balance!`,
  willusepartialbalance: `The bottom line has been reduced by the available balance.`,
  forsale: `Tickets for sale`,
  error: `Error`,
  waiterror: `Error - your request couldn't be fulfilled.`,
  waitsoldout: `Unfortunately all tickets of the request type have been sold.`,
  queuesoldout: `Unfortunately all tickets at this price have been sold.`,
  cartsoldout: `Unfortunately we cannot sell the requested number of tickets anymore.`,
  hmmm: `Hmmm..`,
  couponused: `This promo code is already being used.`,
  codeused: `This code is not valid anymore.`,
  payredirect: `Please wait while we are redirecting you to our payment provider`,
  wrongcoupon: `This promo code is not valid anymore.`,
  wrongcode: `This code is invalid`,
  termspdf: `download/PaymentService_Fizetesi_tajekoztato_EN.pdf`,
  salesoon: `Tickets are not yet on sale. Please check back later.`,
  salecodeonly: `Tickets are available with code only.`,
  salesoldout: `We do not have anymore tickets for this event.`,
  saleclosed: `Sale has been closed.`,
  saleunavailable: `Tickets are not available.`,
  welcomeback: `Welcome back!`,
  logout: `Logout`,
  myprofile: `My profile`,
  free: `Free`,
  issueticket: `Issue ticket`,
  issuetickets: `Issue tickets`,
  ticketsendaddress: `Tickets will be issued to:`,
  parcels: `Parcels`,
  tents: `Tent tickets`,
  accept: `Accept`,
  alternatewaiting: `You are now in a virtual queue because there is a limited number of tickets of the type you have chosen.`,
  alternatewaitingmultiple: `You are now in a virtual queue because there is a limited number of tickets of the types you have chosen.`,
  alternatewaitingdesc: `When someone in front of you leaves the queue, we will make those ticket(s) available to you. The number of remaining tickets might be less than originally requested.`,
  alternatewaitingmax: `Tickets available for sale`,
  alternate: `The requested quantity of a ticket is no longer available.`,
  alternatedesc: `Select one of the options below.`,
  alternatemultiple: `The requested quantity of some tickets no longer available.`,
  alternatemultipledesc: `Select one of the options for each ticket below.`,
  alternatechoiceerror: `Selecting a choice for each ticket is mandatory.`,
  alternatenumberoftickets: `tickets.`,
  testalert: `🚧 Test environment, under development!`,
  seasonpass: `Season Pass`,
  followus: `Follow us`,
  dynamicpricing: `Dynamic pricing`,
  dynamicpricingdesc: `Prices could change due to demand.`,
  dynamicpricingtime: `A cart is valid for % minutes.`,
  dynamicpricingexpired: `The offers on this page have expired. Click reload to to see up-to-date offers.`,
  refundtickets: `Refund / change tickets`,
  refundstep1: `Please enter the email address used to submit the order and the ID of the order. We will send a confirmation code to your email address where you can select the tickets to refund / change.`,
  refundconfirm: `Send verification email`,
  refundnotickets: `Cannot find a refundable order with the details provided.`,
  refundalreadyrequested: `A refund request for this order has already been submitted.`,
  refundstep2: `An email with the confirmation code for refund has been sent. Please click the link in the email or type the code below:`,
  refundconfirmcode: `Confirmation code`,
  refundconfirmbutton: `Confirm`,
  refundcantconfirm: `Cannot find a refund request with the code provided.`,
  refundstep3: `Select the refund method provided by the organizers for the individual tickets using the drop-downs.`,
  unknownprice: `Unknown price`,
  amounttorefund: `Amount to refund`,
  requestrefund: `Confirm my request choices`,
  refundcantprocess: `Cannot process refund request.`,
  refundsuccess: `Successful refund / change request`,
  refundsuccessdescription: `We'll start processing your request soon. You will be notified via email about the rest of the process. Please consider that depending on the workload of our customer service and payment processing partners as well as rules set by event organizers, this process might take longer.`,
  refundid: `Refund request ID`,
  refundorderid: `Order Number (can be found in the subject of the email sent on purchase)`,
  refundnochanges: `Please note that after submitting your request it cannot be changed, so please carefully consider your choices. You will be notified via email about the rest of the process.`,
  refundoptionkeep: `Keep ticket`,
  refundoptionvoucher: `I'll attend next year / Get voucher`,
  refundoptionrefund: `Refund`,
  refundoptionexchange: `Exchange for another ticket`,
  refundselectoption: `Select an option...`,
  refundshippingaddress: `Shipping address`,
  refundshippingdescription: `Please enter your postal address where we can send the selected products.`,
  refundchoice: `My choice`,
  refundoverview: `Overview and submit`,
  refundchange: `Change something`,
  refundreview: `Please review your choices below, make sure everything is correct.`,
  handlingfee: `Handling fee`,
  discount: `Discount`,
  redeemedCoupon: `Redeemed coupon code:`,
  altoption: `Alternate offer`,
  altoptiondescription: `You have the option to choose the alternative offer marked in blue instead of the ticket you originally selected. Please note that the price of this alternative ticket(s) may differ from the ticket(s) you originally chose!`,
  altoptiondescriptionmultiple: `You have the option to choose the alternative offer marked in blue instead of the ticket you originally selected. Please note that the price of this alternative ticket(s) may differ from the ticket(s) you originally chose!`,
  altoptionoriginal: `Requested ticket(s)`,
  altoptionmax: `Buy the remaining maximum available:`,
  altoptionchange: `Change ticket type`,
  altoptionchangemultiple: `Change ticket types`,
  altoptionchangeto: `New ticket:`,
  altoptionchangeoldprice: `Original price:`,
  altoptionchangenewprice: `New price:`,
  altoptionchangenewqunatity: `New quanity:`,
  altoptionchangeprice: `Ticket price`,
  altoptionchangetype: `Ticket type`,
  altoptionremove: `Remove this ticket from my cart`,
  altoptionchangenotice: `Changing ticket types might also change handling fees.`,
  altoptionchangeoverviewnotice: `You will be able to review your order before finalizing it.`,
  continue: `Continue`,
  cart: `Cart`,
  haveaccount: `I have a TIXA account`,
  donthaveaccount: `I don't have a TIXA account`,
  registernewaccount: `Register a new account`,
  continuewithoutaccount: `Continue without registration`,
  invoiceindividualsonlydisclaimer: `According to the Act XIII of 2022 on the "Itemized Tax on Small Businesses" (KATA) introduced on 18 July 2022, we can only issue invoices for this event to individuals.`,
  downloadpdf: `Download PDF`,
  downloadwallet: `Download to Wallet`,
  orderref: `Order`,
  countrylabel: `Country`
};
export { en }