import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

declare const PRODUCTION: boolean
declare const DEVELOPMENT: boolean
declare const COMMITHASH: string

const ISTEST = window && window.location.host.indexOf('teszt') === 0

class ErrorReporter {
  public static init(): void {
    if (PRODUCTION || ISTEST) {
      Sentry.init({
        release: `tixa-web@${COMMITHASH}`,
        dsn: 'https://4e2ce8244b63435f98f621e6af6211ac@o258281.ingest.sentry.io/1452437',
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.5,
        ignoreErrors: [
          '_AutofillCallbackHandler',
          '_pcmBridgeCallbackHandler',
          'Blocked a frame with origin',
        ],
        allowUrls: [/tixa\.hu/i],
        denyUrls: [
          // Facebook flakiness
          /facebook\.com/i,
          // Facebook blocked
          /connect\.facebook\.net\/en_US\/all\.js/i,
          // Safari extensions
          /^safari-web-extension:\/\//i,
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
          // Other plugins
          /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
          // Localhost
          /localhost/i,
        ],
      })
    }
  }

  public static exception(error: any): void {
    if (!(error instanceof Error)) {
      return
    }
    if (DEVELOPMENT || ISTEST) {
      // tslint:disable-next-line:no-console
      console.error(error)
    }
    if (PRODUCTION) {
      // TODO: Replace Sentry
    }
  }
  public static message(message: string, severity: string): void {
    if (DEVELOPMENT || ISTEST) {
      if (
        severity === 'error' ||
        severity === 'fatal' ||
        severity === 'critical'
      ) {
        // tslint:disable-next-line:no-console
        console.error(message)
      }
      if (severity === 'info') {
        // tslint:disable-next-line:no-console
        console.info(message)
      }
      if (severity === 'warning') {
        // tslint:disable-next-line:no-console
        console.warn(message)
      }
      if (severity === 'debug') {
        // tslint:disable-next-line:no-console
        console.debug(message)
      }
    }
    if (PRODUCTION) {
      // TODO: Replace Sentry
    }
  }
}

export { ErrorReporter }
