import { component } from 'knockout-decorators'

import source from './page-footer.html?raw'
import './page-footer.less'

import CreditCardImages from '../../images/cards.svg?raw'
import FacebookLogo from '../../images/logo-facebook.svg?raw'
import LogoImage from '../../images/logo-footer.svg?raw'
import InstagramLogo from '../../images/logo-instagram.svg?raw'
import TixaBlogLogo from '../../images/logo-tixablog.svg?raw'

const SimpleLogo = new URL('../../images/simplepay_200x50.png', import.meta.url)
  .href

@component('page-footer', source)
export class PageFooter {
  private logoImage = LogoImage
  private facebookLogo = FacebookLogo
  private instagramLogo = InstagramLogo
  private blogLogo = TixaBlogLogo
  private creditCardImages = CreditCardImages
  private simpleLogo = SimpleLogo

  constructor(params: any) {
    //
  }
}
