import { computed, observable, observableArray } from 'knockout-decorators'

import * as schema from '@tixa/schema'

import { CartSubmitInteractor } from './interactors/cart-submitter'
import { SpecialOfferInteractor } from './interactors/special-offers'

import { TicketState } from './ticket'
import { ReservationState } from './reservation'

import { getPageData } from '~/utils/ajax'
import { EventSaleStatus } from '@tixa/schema/src'

declare const EMBED: boolean

export class EventState {
  public static get(): EventState {
    if (!EventState.instance) {
      EventState.instance = new EventState()
    }
    return EventState.instance
  }

  private static instance: EventState

  @observable
  public eventConfigs: schema.EventConfigs = EMBED
    ? null
    : getPageData<schema.EventConfigs>('eventConfig')

  @observable
  public event: schema.EventItem = EMBED
    ? null
    : getPageData<schema.EventItem>('eventData')

  @observable
  public cartRequest: Promise<schema.CartResponse> = null

  @observableArray
  public events: schema.EventConfig[] = EMBED ? [] : this.eventConfigs.events

  @observable
  public remainingTime = ''

  @observable
  public hasExpired = false

  @observableArray({ deep: true })
  public offers: schema.Offer[] = this.events
    .filter((event) => !event.hasSeatmap)
    .map((eventOffer) => {
      const offerCatalog = getPageData<schema.OfferCatalog>(
        `eventOffers-${eventOffer.eventID}`
      )
      const availableOffers = offerCatalog.itemListElement.filter(
        (off) => off.availability === schema.OfferAvailability.InStock
      )

      return offerCatalog.itemListElement.map((offer) => {
        offer.eventID = eventOffer.eventID.toString()

        // automatically select the offer
        // when this is the only available offer, and it's a single event
        offer.eligibleQuantity.value =
          this.events.length === 1 &&
          availableOffers.length === 1 &&
          availableOffers[0] === offer
            ? 1
            : 0

        return offer
      })
    })
    .flat()

  @observableArray({ deep: true })
  public reservationOffers: schema.Offer[] = this.events
    .filter((event) => event.hasSeatmap)
    .map((eventOffer) => {
      const offerCatalog = getPageData<schema.OfferCatalog>(
        `eventOffers-${eventOffer.eventID}`
      )
      return offerCatalog.itemListElement
    })
    .flat()

  @computed get offerSates(): Record<string, TicketState> {
    return Object.fromEntries(
      this.offers.map((offer) => [offer['@id'], new TicketState(offer)])
    )
  }

  @computed get reservationStates(): Record<string, ReservationState> {
    return Object.fromEntries(
      this.events
        .filter((event) => event.hasSeatmap)
        .map((event) => [
          event.seatMapID,
          new ReservationState({ seatmapId: event.seatMapID }),
        ])
    )
  }

  @computed get hasTickets(): boolean {
    const offerStateValues = Object.values(this.offerSates)

    const isEventOnSale = (event) => event.saleStatus === EventSaleStatus.onsale

    const isEventCodeOnlyWithOffers = (event) =>
      event.saleStatus === EventSaleStatus.codeonly &&
      offerStateValues.some(
        (state) => state.offer.eventID.toString() === event.eventID.toString()
      )

    const hasOnSaleEvents = this.eventConfigs.events.some(
      (event) => isEventOnSale(event) || isEventCodeOnlyWithOffers(event)
    )

    const hasValidOfferStates = offerStateValues.length > 0
    const reservationOpen =
      Object.values(this.reservationStates).filter(
        (state) => state.isSeatmapOpen
      ).length > 0
    const result = hasOnSaleEvents && (hasValidOfferStates || reservationOpen)

    return result
  }

  @computed get canBuy(): boolean {
    const hasActiveReservations =
      Object.values(this.offerSates).filter((state) => state.submit.isEnabled)
        .length > 0
    const hasActiveTickets =
      Object.values(this.reservationStates).filter(
        (state) => state.submit.isEnabled
      ).length > 0

    const result = hasActiveReservations || hasActiveTickets
    return result
  }

  @computed get handlingFee(): number {
    const fee = this.events
      .filter((event) => event.handlingFee !== undefined)
      .map((event) => {
        const ticketFee = Object.values(this.offerSates)
          .filter(
            (ticket) =>
              ticket.offer.eventID.toString() === event.eventID.toString() &&
              ticket.cartCount > 0 &&
              !ticket.isFree
          )
          .map((ticket) => {
            if (ticket.offer.handlingFee) {
              return ticket.cartCount * ticket.offer.handlingFee
            }
            if (event.handlingFee.ticketPercentage !== undefined) {
              return (
                ticket.cartCount *
                ticket.offer.price *
                (event.handlingFee.ticketPercentage / 100)
              )
            }
            if (event.handlingFee.ticketFixed !== undefined) {
              return ticket.cartCount * event.handlingFee.ticketFixed
            }
            return 0
          })
          .reduce((prev, curr) => prev + curr, 0)

        const eventFee = ticketFee === 0 ? 0 : event.handlingFee.fixed || 0

        return eventFee + ticketFee
      })
      .reduce((prev, curr) => prev + curr, 0)

    return Math.round(fee)
  }

  @computed get isMultiEvent(): boolean {
    return this.events.length > 1
  }

  @observable get allTicketsFree(): boolean {
    return Object.values(this.offerSates).every((offer) => offer.isFree)
  }

  @observable get hasHandlingFee(): boolean {
    return this.eventConfigs.events.some(
      (event) => event.handlingFee !== undefined
    )
  }

  @observable
  public globalError = ''

  public eventConfigById(id: string): schema.EventConfig {
    return this.events.find(
      (event) => event.eventID.toString() === id.toString()
    )
  }

  public offerById(id: string): schema.Offer {
    const byId = (offer: schema.Offer) =>
      offer['@id'].toString() === id.toString()

    const result = this.offers.find(byId) || this.reservationOffers.find(byId)

    if (!result) {
      console.debug(`Offer ${id} cannot be found`, [...this.offers])
    }
    return result
  }

  public offersByEventId(id: string): schema.Offer[] {
    return this.offers.filter(
      (offer) => offer.eventID.toString() === id.toString()
    )
  }

  public specialOffers: SpecialOfferInteractor
  public submitter: CartSubmitInteractor

  private constructor() {
    this.specialOffers = new SpecialOfferInteractor(this)
    this.submitter = new CartSubmitInteractor(this)
  }
}
