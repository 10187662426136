import Modal from 'bootstrap/js/src/modal'
import * as ko from 'knockout'
import { observable } from 'knockout-decorators'

import { Analytics, ErrorReporter } from '~/utils'
import { PaymentStatus } from '@tixa/schema'

import './payment-checker.less'

import ErrorIcon from '~/images/icons/cart_sad.svg?raw'
import TicketImage from '~/images/icons/cart_ticket.svg?raw'

import source from './payment-checker.html?raw'
import { pageState } from '~/store/page'
import { useOrderStore } from '../cart/state/store-order'

declare const EMBED: boolean

export class PaymentChecker {
  private orderStore = useOrderStore()
  private Tracker = Analytics.getInstance()
  private paymentStarted = false
  private paymentModal: any = null
  private paymentTimeout: any = null

  private ticketimage = TicketImage
  private errorIcon = ErrorIcon

  // PAYMENT
  @observable private paymentFeedback = ''
  @observable private isPaymentWaiting = false
  @observable private isPaymentSuccess = false
  @observable private isPaymentFailure = false
  @observable private transactionDate = ''
  @observable private transactionOrderId = ''
  @observable private transactionPaymentId = ''
  @observable private transactionPaymentMode = ''

  constructor() {
    if (document.getElementsByTagName('payment-checker').length === 0) {
      const el = document.createElement('payment-checker')
      el.classList.add('unblock')
      el.innerHTML = source
      document.body.appendChild(el)
      ko.applyBindings(this, el)

      const modalElRq = el.getElementsByClassName('modal')
      if (modalElRq.length > 0) {
        const modalEl = modalElRq[0]
        this.paymentModal = new Modal(modalEl, {
          show: true,
          focus: true,
          keyboard: false,
          backdrop: 'static',
        })
        modalEl.addEventListener('hidden.bs.modal', () => {
          document.body.classList.remove('payment-open')
          window.parent.postMessage({ action: 'setModal', open: false }, '*')
        })
      }
    }
  }

  public async checkPayment(orderId: string) {
    this.isPaymentWaiting = true
    this.paymentModal.show()
    let errorCount = 0
    this.paymentStarted = true

    const storedOrder = await this.orderStore.getOrderData()
    const orderedItems = storedOrder.offers

    if (orderedItems) {
      document.body.classList.add('payment-open')
      window.parent.postMessage({ action: 'setModal', open: true }, '*')
      const doCheck = (order: string) => {
        if (!this.isPaymentWaiting) {
          return
        }
        pageState.api.order
          .checkPayment(orderId)
          .then(async (value) => {
            if (value.status === PaymentStatus.pending) {
              this.paymentTimeout = setTimeout(() => doCheck(order), 2000)
            } else if (value.status === PaymentStatus.success) {
              this.Tracker.purchased(
                storedOrder.orderId,
                orderedItems,
                storedOrder.cartId,
                storedOrder
              )
              this.isPaymentSuccess = true
              this.isPaymentWaiting = false
              await this.orderStore.clear()
              try {
                if (!EMBED) {
                  await pageState.api.impression.submit({
                    action: 'completed',
                    id: orderId,
                    type: 'purchase',
                  })
                }
              } catch (error) {
                ErrorReporter.exception(error)
              }
            } else if (value.status === PaymentStatus.failure) {
              this.Tracker.paymentFailure()
              if (value.reason) {
                this.paymentFeedback = value.reason
              }
              this.isPaymentWaiting = false
              this.isPaymentFailure = true
              await this.orderStore.clear()
              try {
                if (!EMBED) {
                  await pageState.api.impression.submit({
                    action: 'failure',
                    id: orderId,
                    type: 'purchase',
                  })
                }
              } catch (error) {
                ErrorReporter.exception(error)
              }
            }
            if (value.transaction) {
              this.transactionDate = value.transaction.date
              this.transactionOrderId = value.transaction.orderId
              this.transactionPaymentId = value.transaction.paymentId
              this.transactionPaymentMode = value.transaction.paymentMode
            }
          })
          .catch((error) => {
            ErrorReporter.exception(error)
            errorCount = errorCount + 1
            if (errorCount < 10) {
              this.paymentTimeout = setTimeout(() => doCheck(order), 2000)
            } else {
              this.paymentFeedback = error.response.status
              this.isPaymentWaiting = false
              this.isPaymentFailure = true
            }
          })
      }
      doCheck(orderId)
    }
  }
}
